<template>
  <div>
    <!-- Start Footer Area  -->
    <div class="rn-footer position">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        © 2020-{{ new Date().getFullYear() }} sunyung.cn 版权所有 ICP证： 浙ICP备2022023544号-2
      </a>
      <!-- <span>Copyright  2022 All Rights Reserved</span>
            <span href="https://beian.miit.gov.cn/" target="_blank">联系我们：400-806-1696</span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'rightsFooter',

  props: {
    data: {
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
.position {
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
}
.rn-footer {
  height: 96px;
  // border-top: 1px solid #e6e6e6;
  background: #fff;
  text-align: center;
  // line-height: 96px;
  font-size: 12px;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-right: 12px;
  }
  a {
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0px;

    color: #999999;
    text-decoration: none;
  }
}
@media only screen and (max-width: 1199px) {
  // .rn-footer {
  //   display: none;
  // }
}
</style>
